import { Controller } from 'stimulus';
import { fetchGet } from '../plugins/api';

export default class extends Controller {
  static targets = ['circle', 'stateLabel', 'wrapper'];

  static values = {
    url: String,
  };

  connect() {
    console.log('Switch button controller connected');
  }

  switchButtonDisplay() {
    this.circleTarget.classList.toggle('active');
  }

  switchStateLabel() {
    this.stateLabelTargets.forEach(stateLabel => {
      stateLabel.classList.toggle('hidden');
    });
  }

  async switch() {
    const { redirection_url } = JSON.parse(await fetchGet(this.urlValue));
    if (redirection_url) window.location = redirection_url;
  }
}
