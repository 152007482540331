import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let isIE =
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.userAgent.indexOf('Trident') !== -1 ||
      navigator.userAgent.indexOf('Microsoft Internet Explorer') !== -1;
    if (isIE) {
      if (window.location.pathname !== '/unsupported-browser') {
        window.location.pathname = '/unsupported-browser';
      }
    }
  }
}
