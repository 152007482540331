import { Controller } from 'stimulus';
import { fetchGet, fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = [
    'emptyStar',
    'fullStar',
    'ratedMessageContainer',
    'ratingStarsContainer',
    'ratingBox',
    'title',
    'valueInput',
    'form',
  ];
  static values = {
    user: String,
    item: String,
  };

  getIdNumber(id) {
    return Number.parseInt(id.split('-')[1]);
  }

  fillStars(id) {
    this.emptyStarTargets.forEach(star => {
      if (this.getIdNumber(star.id) <= id) {
        star.classList.add('hidden');
      } else {
        star.classList.remove('hidden');
      }
    });
    this.fullStarTargets.forEach(star => {
      if (this.getIdNumber(star.id) <= id) {
        star.classList.remove('hidden');
      } else {
        star.classList.add('hidden');
      }
    });
  }

  fillValue(id) {
    this.valueInputTarget.value = id + 1;
  }

  rate(e) {
    const targetId = e.target.id;
    const id = this.getIdNumber(targetId);

    this.fillStars(id);
    this.fillValue(id);
    this.formTarget.requestSubmit();
  }
}
