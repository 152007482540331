import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'radio'];

  connect() {
    this.update();
  }

  update() {
    const selectedValue = this.radioTargets.find(radio => radio.checked)?.value

    if (selectedValue === "true") {
      this.showContent();
    } else {
      this.hideContent();
    }
  }

  showContent() {
    this.contentTargets.map(target => {
      target.classList.remove('hidden');
    });
  }

  hideContent() {
    this.contentTargets.map(target => {
      target.classList.add('hidden');
    });
  }
}
