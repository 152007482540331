import { Controller } from 'stimulus';
import { fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = ['issue', 'mainIssue', 'modal', 'submitButton'];
  static values = {
    userId: Number,
  };

  selectIssue(event) {
    let { target } = event;

    while (target.tagName !== 'LI') {
      target = target.parentElement;
    }

    if (target.classList.contains('selected')) {
      target.classList.remove('selected');
      this.data.set('selectedIssue', '');
      this.data.set('selectedIssueName', '');
      this.submitButtonTarget.classList.add('disabled');
    } else {
      this.issueTargets.forEach(issue => issue.classList.remove('selected'));
      target.classList.add('selected');
      this.data.set('selectedIssue', target.dataset.value);
      this.data.set('selectedIssueName', target.innerText);
      this.submitButtonTarget.classList.remove('disabled');
    }
  }

  isProgressPage() {
    return window.location.href.indexOf('profiling/results') > -1;
  }

  async setIssue() {
    if (this.submitButtonTarget.classList.contains('disabled')) return;
    const url = `/profiling/main-issue`;
    const body = {
      main_issue: {
        value: this.data.get('selectedIssue'),
      },
    };

    if (this.userIdValue) {
      body.user_id = this.userIdValue;
    }

    await fetchPut('post', url, body);

    this.modalTarget.classList.add('hidden');
    this.mainIssueTarget.innerText = this.data.get('selectedIssueName');
  }
}
