import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'maskable', 'sideEffectContent'];

  toggle() {
    this.contentTargets.map(target => {
      target.classList.toggle(this.data.get('class'));
    });
  }

  mask() {
    this.maskableTargets.map(target => {
      -target.classList.toggle(this.data.get('subclass'));
    });
  }
}
