import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'videoContainer',
    'accessDeniedMessage',
    'enterRoomButton',
    'permissionDeniedMessage',
    'permissionRequestMessage',
    'permissionRequestOngoingMessage',
    'videoSettings',
    'selectCamera',
    'selectMic',
    'selectSpeaker',
    'speakerSelectorContainer',
  ];

  connect() {
    if (this.videoContainerTarget) {

      navigator.permissions.query({ name: 'microphone' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'denied') {
            this.activatePermissionsDenied();
          } else if (permissionStatus.state === 'prompt') {
            this.activatePermissionsRequest();
          } else {
            this.initPublisher();
          }
        })
        .catch(error => {
          console.error('Error getting camera permissions:', error);
          this.initPublisher();
        });
    }
  }

  initPublisher() {
    this.permissionRequestMessageTarget.classList.add('hidden');
    this.permissionDeniedMessageTarget.classList.add('hidden');
    this.populateDevicesSources()
      .then(() => {
        console.log('Populated devices sources');
        this.showVideoSettings()
        this.permissionRequestOngoingMessageTarget.classList.add('hidden');
        this.enableEnterRoomButton()
        this.unsetAccessDeniedMessage();
        this.publishStream();
      });
  }

  publishStream(deviceOptions) {
    if (this.publisher) {
      console.log('Publisher present (destroying …)', this.publisher);
      this.publisher.destroy();
    }

    this.publisher = OT.initPublisher(
      'video-container',
      {
        ...deviceOptions,
        insertMode: 'append',
        width: '100%',
        height: '100%',
        showControls: false,
      },
      this.handlePublisherInit.bind(this)
    );
  }

  handlePublisherInit(error) {
    if (error) {
      if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
        this.activatePermissionsDenied();
      }
    }
  }

  activatePermissionsDenied() {
    this.showPermissionDeniedMessage();
    this.disableEnterRoomButton();
    this.setAccessDeniedMessage();
    this.hideVideoSettings();
    this.permissionRequestMessageTarget.classList.add('hidden');
    this.permissionRequestOngoingMessageTarget.classList.add('hidden');
  }

  activatePermissionsRequest() {
    this.showPermissionRequestMessage();
    this.disableEnterRoomButton();
    this.setAccessDeniedMessage();
    this.hideVideoSettings();
  }

  allowAccessToRoom() {
    this.unsetAccessDeniedMessage();
    this.enableEnterRoomButton();
  }

  hideVideoSettings() {
    this.videoSettingsTarget.classList.add('hidden');
  }

  showVideoSettings() {
    this.videoSettingsTarget.classList.remove('hidden');
  }

  showPermissionDeniedMessage() {
    this.permissionDeniedMessageTarget.classList.remove('hidden');
  }

  showPermissionRequestMessage() {
    this.permissionRequestMessageTarget.classList.remove('hidden');
  }

  showPermissionRequestOngoingMessage() {
    console.log('Requesting permission');
    this.permissionRequestOngoingMessageTarget.classList.remove('hidden');
  }

  setAccessDeniedMessage() {
    console.log('Access Denied');
    this.accessDeniedMessageTarget.classList.add('call-tips-error');
  }

  unsetAccessDeniedMessage() {
    this.accessDeniedMessageTarget.classList.remove('call-tips-error');
  }

  disableEnterRoomButton() {
    this.enterRoomButtonTarget.setAttribute('disabled', 'true');
    this.enterRoomButtonTarget.classList.add('disabled')
  }

  enableEnterRoomButton() {
    this.enterRoomButtonTarget.removeAttribute('disabled');
    this.enterRoomButtonTarget.classList.remove('disabled')
  }

  enterRoom(event) {
    if (this.enterRoomButtonTarget.hasAttribute('disabled')) {
      event.preventDefault();
    }
  }

  // Camera settings

  populateDevicesSources() {
    console.log('Populating all devices');
    return new Promise((resolve, reject) => {
      OT.getUserMedia()
        .catch(error => {
          if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
            this.activatePermissionsDenied();
          }
        })
        .then((stream) => {
          this.populateAudioSources();
          this.populateVideoSources();
          stream.getTracks().forEach(track => track.stop());
        })
        .then(() => {
          this.populateAudioOutputDevices();
        })
        .then(() => {
          resolve();
        })
    });
  }

  populateAudioSources() {
    console.log('Populating Mic devices');
    OT.getDevices((err, devices) => {
      if (err) {
        alert('getDevices error ' + err.message);
        return;
      }
      let index = 0;
      this.selectMicTarget.innerHTML = devices.reduce((innerHTML, device) => {
        if (device.kind === 'audioInput') {
          index += 1;
          return `${innerHTML}<option value="${device.deviceId}">${device.label || device.kind + index}</option>`;
        }
        return innerHTML;
      }, '');
      this.setStoredMicrophoneChoice();
    });
  }

  populateVideoSources() {
    console.log('Populating Video Devices');
    OT.getDevices((err, devices) => {
      if (err) {
        alert('getDevices error ' + err.message);
        return;
      }
      let index = 0;
      this.selectCameraTarget.innerHTML = devices.reduce((innerHTML, device) => {
        if (device.kind === 'videoInput') {
          index += 1;
          console.log(device.label);
          return `${innerHTML}<option value="${device.deviceId}">${device.label || device.kind + index}</option>`;
        }
        return innerHTML;
      }, '');
      this.setStoredCameraChoice();
    });
  }

  populateAudioOutputDevices() {
    console.log('Populating Speaker devices');

    OT.getAudioOutputDevices()
      .then(devices => {
        console.log('Audio Output devices', devices);
        let index = 0;

        if (devices.length === 0) {
          console.warn('No audio output devices found.');
          this.speakerSelectorContainerTarget.classList.add('hidden');
        } else {
          console.log('Found some audio output devices');
          this.selectSpeakerTarget.innerHTML = devices.reduce((innerHTML, device) => {
            index += 1;
            return `${innerHTML}<option value="${device.deviceId}">${device.label || `output ${index}`}</option>`;
          }, '')
        }

        this.setStoredSpeakerChoice();
      })
      .catch(error => {
        console.error('Error getting audio output devices:', error);
      });
  }

  setStoredMicrophoneChoice() {
    const storedMic = localStorage.getItem('selectedMic');

    const optionForStoredMic = Array.from(this.selectMicTarget.options).find(option => option.text === storedMic);
    console.log('storedMic', optionForStoredMic);

    if (optionForStoredMic) {
      this.currentMicDeviceId = optionForStoredMic.value;
      this.selectMicTarget.value = optionForStoredMic.value;
    }
  }

  setStoredCameraChoice() {
    const storedCamera = localStorage.getItem('selectedCamera');

    const optionForStoredCamera = Array.from(this.selectCameraTarget.options).find(option => option.text === storedCamera);
    console.log('storedCamera', optionForStoredCamera);

    if (optionForStoredCamera) {
      this.currentVideoDeviceId = optionForStoredCamera.value;
      this.selectCameraTarget.value = optionForStoredCamera.value;
    }
  }

  setStoredSpeakerChoice() {
    const storedSpeaker = localStorage.getItem('selectedSpeaker');

    const optionForStoredSpeaker = Array.from(this.selectSpeakerTarget.options).find(option => option.text === storedSpeaker);
    console.log('storedSpeaker', optionForStoredSpeaker);

    if (optionForStoredSpeaker) {
      this.currentSpeakerDeviceId = optionForStoredSpeaker.value;
      this.selectSpeakerTarget.value = optionForStoredSpeaker.value;
    }
  }

  selectCamera() {
    const selectedDeviceId = this.selectSpeakerTarget.value;
    const selectedDeviceLabel = this.selectSpeakerTarget.options[this.selectSpeakerTarget.selectedIndex].text;
    console.log('Selected speaker:', selectedDeviceLabel, selectedDeviceId);
    localStorage.setItem('selectedSpeaker', selectedDeviceLabel);
    videoTherapy.videoPublisher.setVideoSource(selectedDeviceId);
  }

  selectMic() {
    const selectedDeviceId = this.selectMicTarget.value;
    const selectedDeviceLabel = this.selectMicTarget.options[this.selectMicTarget.selectedIndex].text;
    console.log('Selected mic:', selectedDeviceLabel, selectedDeviceId);
    localStorage.setItem('selectedMic', selectedDeviceLabel);
    videoTherapy.videoPublisher.setAudioSource(selectedDeviceId);
  }

  selectSpeaker() {
    const selectedDeviceId = this.selectSpeakerTarget.value;
    const selectedDeviceLabel = this.selectSpeakerTarget.options[this.selectSpeakerTarget.selectedIndex].text;
    console.log('Selected speaker:', selectedDeviceLabel, selectedDeviceId);
    localStorage.setItem('selectedSpeaker', selectedDeviceLabel);
    OT.setAudioOutputDevice(selectedDeviceId);
  }

  askPermission(event) {
    event.preventDefault();
    this.showPermissionRequestOngoingMessage();
    this.permissionRequestOngoingMessageTarget.classList.remove('hidden');
    this.initPublisher();
  }
}
