import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'publisher',
    'message',
    'audioSelect',
    'videoSelect',
    'audioOutputSelect',
    'playAudioButton',
    'pauseAudioButton',
    'audioElement',
    'audioMeter',
    'connection',
    'permissionDeniedMessage',
    'noAudioOutputDevicesMessage',
    'audioOutputSelectContainer',
    'audioTestContainer',
    'audioLevelContainer',
    'connectionFast',
    'connectionSlow',
  ];

  static values = {
    apiKey: String,
    sessionId: String,
    token: String,
    locale: String,
  };

  connect() {
    this.initializeSession();
  }

  initializeSession() {
    this.session = OT.initSession(this.apiKeyValue, this.sessionIdValue);

    // this.publisher = OT.initPublisher(this.publisherTarget, {
    //   insertMode: 'append',
    //   width: '100%',
    //   height: '100%',
    //   showControls: false,
    // });

    this.session.on('sessionConnected', (event) => {
      this.populateDevicesSources();
      this.populateAudioOutputDevices();
      this.setConnectionSpeed();
      this.publishStream();
    });

    this.session.on('streamCreated', (event) => {
      const subscriberOptions = {
        insertMode: 'append',
        fitMode: 'contain',
      };

      console.log('subscriberOptions', subscriberOptions);

      this.session.subscribe(event.stream, 'video-test-subscriber', subscriberOptions, this.handleError);
    });

    this.session.connect(this.tokenValue, err => {
      if (err) {
        console.error('Failed to connect to session:', err);
      } else {
        console.log('Session connected without error');
      }
    });
  }

  publishStream(deviceOptions) {
    console.log('SESSION:', this.session.isConnected());

    if (this.publisher) {
      console.log('Publisher present (destroying …)', this.publisher);
      this.publisher.destroy();
    }

    this.publisher = OT.initPublisher('publisher', {
      ...deviceOptions,
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
    });

    this.publisher.on('accessAllowed', () => {
      console.log('publisher accessAllowed');
    });

    this.publisher.on('accessDenied', () => {
      console.log('publisher accessDenied');
    });

    this.publisher.on('streamCreated', () => {
      this.setupAudioLevelMeter();
    });

    this.session.publish(this.publisher, err => {
      if (err) {
        console.error('Failed to publish stream:', err);
      }
    });
  }

  setStoredMicrophoneChoices() {
    const storedMicrophone = localStorage.getItem('selectedMicrophone');

    console.log('storedMicrophone', storedMicrophone);

    if (storedMicrophone) {
      this.audioSelectTarget.value = storedMicrophone;
      this.selectMicrophone();
    }
  }

  setStoredCameraChoices() {
    const storedCamera = localStorage.getItem('selectedCamera');

    console.log('storedCamera', storedCamera);

    if (storedCamera) {
      this.videoSelectTarget.value = storedCamera;
      this.selectCamera();
    }
  }

  setStoredAudioOutputChoice() {
    const storedAudioOutput = localStorage.getItem('selectedAudioOutput');
    console.log('storedAudioOutput', storedAudioOutput);
    if (storedAudioOutput) {
      this.audioOutputSelectTarget.value = storedAudioOutput;
      console.log('audioOutputSelectTarget value', this.audioOutputSelectTarget.value);
      this.selectAudioOutput();
    }
  }

  populateDevicesSources() {
    OT.getUserMedia()
      .then((stream) => {
        this.populateAudioSources();
        this.populateVideoSources();
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(error => {
        console.log('Error getting user media:', error);
        if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          this.showPermissionsDenied();
        }
      });
  }

  populateAudioSources() {
    OT.getDevices((err, devices) => {
      if (err) {
        alert('getDevices error ' + err.message);
        return;
      }
      let index = 0;
      this.audioSelectTarget.innerHTML = devices.reduce((innerHTML, device) => {
        if (device.kind === 'audioInput') {
          index += 1;
          return `${innerHTML}<option value="${device.deviceId}">${device.label || device.kind + index}</option>`;
        }
        return innerHTML;
      }, '');
      this.setStoredMicrophoneChoices();
    });
  }

  populateVideoSources() {
    OT.getDevices((err, devices) => {
      if (err) {
        alert('getDevices error ' + err.message);
        return;
      }
      let index = 0;
      this.videoSelectTarget.innerHTML = devices.reduce((innerHTML, device) => {
        if (device.kind === 'videoInput') {
          index += 1;
          return `${innerHTML}<option value="${device.deviceId}">${device.label || device.kind + index}</option>`;
        }
        return innerHTML;
      }, '');
      this.setStoredCameraChoices();
    });
  }

  populateAudioOutputDevices() {
    console.log('populateAudioOutputDevices');
    OT.getAudioOutputDevices()
      .then(devices => {
        if (devices.length === 0) {
          console.warn('No audio output devices found.');
          this.showNoAudioOutputDevicesMessage();
          return;
        } else {
          if (devices[0].deviceId === undefined) {
            console.warn('No audio output devices found.');
            this.showOnlyOneAudioOutputDeviceMessage(devices[0]);
          } else {
            devices.forEach(device => {
              const option = document.createElement('option');
              option.value = device.deviceId;
              option.text = device.label || `${this.audioOutputSelectTarget.length + 1}`;
              this.audioOutputSelectTarget.appendChild(option);
            });

            if (devices.length === 1) {
              this.showOnlyOneAudioOutputDeviceMessage(devices[0]);
            }
            this.setupAudioTest();
          }
        }
        this.setStoredAudioOutputChoice();
      })
      .catch(error => {
        console.error('Error getting audio output devices:', error);
      });
  }

  setupAudioTest() {
    const audio = new Audio(this.audioSourceValue);
    audio.crossOrigin = 'anonymous';
    this.audioValue = audio;
    this.audioContext = new AudioContext();
    const source = this.audioContext.createMediaElementSource(audio);
    source.connect(this.audioContext.destination);
    this.audioTestContainerTarget.classList.remove('hidden');
  }

  selectMicrophone() {
    const selectedDeviceId = this.audioSelectTarget.value;
    localStorage.setItem('selectedMicrophone', selectedDeviceId);
    this.publishStream({ audioSource: selectedDeviceId });
  }

  selectCamera() {
    const selectedDeviceId = this.videoSelectTarget.value;
    localStorage.setItem('selectedCamera', selectedDeviceId);
    this.publishStream({ videoSource: selectedDeviceId });
  }

  selectAudioOutput() {
    const selectedDeviceId = this.audioOutputSelectTarget.value;
    console.log('selectedDeviceId', selectedDeviceId);

    localStorage.setItem('selectedAudioOutput', selectedDeviceId);
    if (typeof this.audioElementTarget.sinkId !== "undefined") {
      console.log('selectAudioOutput', selectedDeviceId);
      this.audioElementTarget.setSinkId(selectedDeviceId);
    } else {
      console.warn("Browser does not support output device selection.");
    }
  }

  playAudio(event) {
    console.log('playAudio');
    this.audioElementTarget.play();
    this.playAudioButtonTarget.classList.add('hidden');
    this.pauseAudioButtonTarget.classList.remove('hidden');
  }

  pauseAudio(event) {
    console.log('pauseAudio');
    this.audioElementTarget.pause();
    this.pauseAudioButtonTarget.classList.add('hidden');
    this.playAudioButtonTarget.classList.remove('hidden');
  }

  setupAudioLevelMeter() {
    console.log('setupAudioLevelMeter');
    this.audioLevelContainerTarget.classList.remove('hidden');
    const audioLevel = this.audioMeterTarget;
    const meter = audioLevel.querySelector('meter');
    let movingAvg = null;
    this.publisher.on('audioLevelUpdated', (event) => {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      meter.value = logLevel;
    });
  }

  setConnectionSpeed() {
    if (this.hasFastConnection()) {
      this.connectionFastTarget.classList.remove('hidden');
    } else {
      console.log('Slow connection');
      this.connectionSlowTarget.classList.remove('hidden');
    }
  }

  hasFastConnection() {
    const connection = navigator.connection
      || navigator.mozConnection
      || navigator.webkitConnection;
    console.log('connection', connection.type);
    console.log('connection', connection.effectiveType);
    return (!connection || (
      !connection.saveData
      && connection.type !== 'none'
      && connection.effectiveType !== 'slow-2g'
      && connection.effectiveType !== '2g'
      && connection.effectiveType !== 'slow-3g'
      && connection.effectiveType !== '3g'
      && connection.effectiveType !== '3g'
    ));
  }

  showPermissionsDenied() {
    this.messageTarget.classList.add('alert');
    this.permissionDeniedMessageTarget.classList.remove('hidden');
    this.messageTarget.classList.remove('hidden');
    console.error('Permissions denied:', error);
  }

  showNoAudioOutputDevicesMessage() {
    this.audioOutputSelectTarget.classList.add('hidden');
    this.noAudioOutputDevicesMessageTarget.classList.remove('hidden');
  }

  showOnlyOneAudioOutputDeviceMessage(device) {
    this.audioOutputSelectTarget.classList.add('hidden');
    this.audioOutputSelectContainerTarget.injectionHTML = `<p>${device.label || 'Audio output device'}</p>`;
  }

  handleError(error) {
    if (error) {
      console.error(error);
    }
  }
}
