import { Controller } from 'stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = ['container'];
  static values = { url: String };

  connect() {
    console.log('Topbar Controller connected');
  }

  async close(event) {
    event.preventDefault();
    this.mark_as_read();
  }

  async close_and_follow(_event) {
    this.mark_as_read();
  }

  async mark_as_read() {
    const request = new FetchRequest('get', this.urlValue);
    const response = await request.perform();

    if (response.ok) {
      this.containerTarget.remove();
    }

    return response.statusCode;
  }
}
