require('@rails/activestorage').start();
require('channels');
import '@hotwired/turbo-rails';

import '../stylesheets/layout/header_minimal.scss';

import ahoy from 'ahoy.js';
import '../plugins/stripe';
import '../controllers/loaders/loader_front';

ahoy.trackClicks();
ahoy.trackView();

require('trix');
require('@rails/actiontext');
