import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['form', 'select'];

  connect() {
    console.log('Submit On Select connected');
  }

  submit() {
    console.log('Submit On Select submit');
    this.formTarget.submit();
  }
}
