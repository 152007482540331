import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'search',
    'categoriesDrawer',
    'typesDrawer',
    'typeFilter',
    'categoryFilter',
    'lastCategoryClicked',
    'searchContainer',
    'contentTypesList',
    'contentTypesPicto',
  ];
  static values = {
    state: String,
  };

  connect() {
    const value = this.searchTarget.value; // Gets the current value of the search input
    this.searchTarget.value = ''; // Sets the value to an empty string
    this.searchTarget.value = value; // Sets it back to the original value, moving the cursor to the end
    if (this.searchTarget.value.length > 0) {
      this.searchTarget.focus();
      this.searchContainerTarget.classList.add('search-open');
    }
  }

  showSearch() {
    this.searchContainerTarget.classList.add('search-open');
    this.searchTarget.focus();
  }

  changeType(e) {
    this.typeFilterTargets.forEach(filter => {
      if (filter.querySelector('input').value === e.target.value) {
        filter.classList.add('selected');
      } else {
        filter.classList.remove('selected');
      }
    });
    this.formTarget.requestSubmit();
  }

  closeFocusedElements(e) {
    if (!this.contentTypesListTarget.classList.contains('hidden')) {
      this.contentTypesListTarget.classList.add('hidden');
    }

    if (
      this.searchContainerTarget.classList.contains('search-open') &&
      e.target.id !== 'search-input' &&
      e.target.id !== 'search-picto'
    ) {
      this.searchContainerTarget.classList.remove('search-open');
    }
  }

  toggleContentTypesList(e) {
    this.contentTypesListTarget.classList.toggle('hidden');
  }
}
