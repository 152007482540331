import { Controller } from 'stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = ['drawer', 'content', 'headerTitle'];

  connect() { }

  open(event) {
    event.preventDefault();
    console.log('OPEN');

    this.replaceSpinners();

    this.drawerTarget.classList.add('open');

    const url = event.params.url;
    this.fetchContent(url);
  }

  close(event) {
    event.preventDefault();
    this.drawerTarget.classList.remove('open');
  }

  async fetchContent(url) {
    const request = new FetchRequest('get', url, { responseKind: 'turbo-stream' });
    const response = await request.perform();
  }

  replaceSpinners() {
    this.replaceContentBySpinner();
    this.replaceHeaderTitleBySpinner();
  }

  replaceContentBySpinner() {
    this.contentTarget.innerHTML = '<div class="loader-container"><div class="main-loader" style="--spinner-size: 4rem; --spinner-border-size: 6px;" ><span></span></div></div>';
  }

  replaceHeaderTitleBySpinner() {
    this.headerTitleTarget.innerHTML = '<div class="loader-container"><div class="main-loader" style="--spinner-size: 2rem; --spinner-border-size: 3px;" ><span></span></div></div>';
  }
}
