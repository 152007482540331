import { Controller } from 'stimulus';
import { fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = ['poll', 'pollButton', 'submitButton'];
  static values = {
    path: String,
    close: String
  }

  connect() {
    this.pollTarget.classList.remove('invisible');
  }

  close() {
    if(this.closeValue === 'invisible'){
      this.pollTarget.classList.add('invisible');
    }

    if(this.closeValue === 'redirection'){
      Turbo.visit('/');
    }
  }

  setOption(e) {
    this.pollButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });
    this.data.set('option', e.target.dataset.value);
    e.target.classList.add('selected');
    this.submitButtonTarget.disabled = false;
  }

  async submit(e) {
    e.preventDefault();
    if (!!this.submitButtonTarget.disabled) return;

    let params = {}
    let key = this.pathValue
    params[key] = { value: this.data.get('option') }
    const url = `/${key}`;
    await fetchPut('post', url, params);
    this.close();
  }
}
