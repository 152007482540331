import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  connect() {
    if (document.querySelectorAll('.completed-step').length > 0)
      this.drawTimeline();
  }

  drawTimeline() {
    const steps = document.querySelectorAll('.program-step-card');
    const completedSteps = Array.from(steps).filter(step =>
      step.classList.contains('completed-step')
    );
    const height = (completedSteps.length / steps.length) * 100;
    if (completedSteps.length > 0) {
      this.contentTarget.insertAdjacentHTML(
        'afterbegin',
        `<div class="arrowed-line" style="background-color: #4dbbbd; height: ${height}%; width: 2px; position: absolute; top: 72px; z-index: 8;"></div>`
      );
    }
  }
}
