// import { Controller } from 'stimulus';
// import flatpickr from 'flatpickr';
//
// export default class extends Controller {
//   connect() {
//     const defaultDate = this.element.value || 'today';
//     flatpickr(this.element, {
//       defaultDate: defaultDate,
//       dateFormat: 'd/m/Y',
//     });
//   }
// }

import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      dateFormat: 'd/m/Y', // Customize the date format as per your needs
      onClose: (selectedDates, dateStr, instance) => {
        if (!dateStr) {
          instance.clear(); // Clear the selected date if the user closes the date picker without selecting a date
        }
      },
    });
  }
}
