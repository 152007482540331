import { Controller } from '@hotwired/stimulus';
import { fetchGet } from '../plugins/api';

export default class PhoneController extends Controller {
  static targets = ['hint'];
  static values = {
    apiUrl: String,
    countryCode: String,
  };

  connect() {
    if (this.hasCountryCodeValue) {
      this.setPlaceholder(this.countryCodeValue);
    }
  }

  async injectPlaceholder(e) {
    await this.setPlaceholder(e.target.value);
  }

  async setPlaceholder(country_code) {
    const { placeholder } = JSON.parse(
      await fetchGet(this.apiUrlValue, {
        code: country_code,
      })
    );

    if (placeholder) this.hintTarget.innerText = placeholder;
  }
}
