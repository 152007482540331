import { Controller } from 'stimulus';
import { fetchGet } from '../plugins/api';

export default class extends Controller {
  static targets = [
    'entries',
    'languages',
    'selectAllLanguages',
    'selectedLanguages',
  ];

  selectLanguages(e) {
    // We need to prevent default as the base "check" does not make the input available on first event
    e.preventDefault();
    const target = document.querySelector(
      `input[name="${e.target.dataset.input}"]`
    );

    target.checked = !target.checked;

    const selectAllLabel =
      this.selectAllLanguagesTarget.parentNode.querySelector('label').innerText;

    // This unselects all languages if the "all" option is checked
    // It also unchecks the "all" option if any other language is checked
    if (target === this.selectAllLanguagesTarget) {
      this.languagesTargets.forEach(el => {
        el.checked = false;
      });
    } else {
      this.selectAllLanguagesTarget.checked = false;
    }

    // This one checks the languages selected
    const checkedLanguages = this.languagesTargets
      .filter(el => el.checked)
      .map(el => {
        return {
          label: el.parentNode.querySelector('label').innerText,
          value: el.value,
        };
      });

    this.data.set(
      'languages',
      checkedLanguages.map(el => el.value)
    );

    this.selectedLanguagesTarget.value =
      checkedLanguages.length > 0
        ? checkedLanguages.map(el => el.label).join(', ')
        : selectAllLabel;

    this.filterLanguages();
  }

  async filterLanguages() {
    const url = '/therapists';
    const languagesCodes = this.data.get('languages');

    let params = {};
    if (languagesCodes && languagesCodes.length > 0)
      params.codes = languagesCodes;

    const { entries } = JSON.parse(await fetchGet(url, params));

    this.entriesTarget.innerHTML = entries;
  }
}
