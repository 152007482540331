import { Controller } from 'stimulus';
import { fetchGet, fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = [
    'contentContainer',
    'customInput',
    'dataPoints',
    'mainContainer',
    'nextBtn',
    'poll',
    'pollButton',
    'prevBtn',
    'previousPoll',
    'submitButton',
    'template',
    'textToFit',
  ];

  static values = {
    dataPointId: Number,
    dataCategoryId: Number,
    userStep: Number,
    polls: Array,
    pollId: Number,
    newData: Boolean,
  };

  async connect() {
    await this.displayPoll(this.userStepValue);
  }

  async displayPoll(id, direction) {
    this.data.set('newData', 'false');
    const {
      poll_id: pollId,
      data_points: dataPoints,
      poll,
    } = JSON.parse(
      await fetchGet('/onboarding/poll', {
        id,
        direction,
      })
    );

    this.pollIdValue = pollId;
    this.data.set(
      'dataPoints',
      dataPoints !== undefined && dataPoints !== null
        ? JSON.stringify(dataPoints)
        : ''
    );

    const slideContainer = this.mainContainerTarget.querySelector(
      '.full-slide-container'
    );
    if (slideContainer) slideContainer.style = 'margin-right: 100%;';

    this.mainContainerTarget.innerHTML = poll;

    window.scroll(0, 0);

    this.fillDataPoints();
  }

  async onChangePoll(e) {
    if (e.target.classList.contains('inactive')) return;
    if (
      this.pollIdValue === this.pollsValue[this.pollsValue.length - 1] &&
      e.target.id === 'next'
    )
      Turbo.visit('/profiling/results');

    const direction = e.target.id;

    const targetId =
      e.target.id === 'prev'
        ? this.pollsValue[this.pollsValue.indexOf(this.pollIdValue) - 1]
        : this.pollsValue[this.pollsValue.indexOf(this.pollIdValue) + 1];

    await this.displayPoll(targetId, direction);
  }

  fillDataPoints() {
    const dataPoints = this.data.get('dataPoints');

    if (
      (this.templateTarget.value !== 'text_illustration' &&
        (dataPoints === null ||
          dataPoints === undefined ||
          dataPoints === 'undefined' ||
          dataPoints.length === 0)) ||
      this.pollIdValue === this.pollsValue[this.pollsValue.length - 1]
    ) {
      this.nextBtnTarget.classList.add('inactive');
      if (this.pollIdValue !== this.pollsValue[this.pollsValue.length - 1])
        this.submitButtonTarget.disabled = true;
    }

    if (this.templateTarget.value === 'multiple_options') {
      const selectedOptions = this.pollButtonTargets.filter(option =>
        option.classList.contains('selected')
      );

      let selectedDataPoints = [];

      this.pollButtonTargets.forEach(option => {
        if (option.classList.contains('selected'))
          selectedDataPoints.push(option.dataset.value);
      });

      this.data.set('options', selectedDataPoints);

      if (selectedOptions.length >= 3) {
        this.pollButtonTargets.forEach(option => {
          if (!option.classList.contains('selected'))
            option.classList.add('deactivated');
        });
      }
    }

    if (this.pollIdValue === this.pollsValue[0]) {
      this.prevBtnTarget.classList.add('inactive');
    }
  }

  setOption(e) {
    this.data.set('newData', 'true');
    this.pollButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });

    e.target.classList.add('selected');

    const value = e.target.dataset.value;
    if (value === 'other') {
      this.customInputTarget.classList.remove('hidden');
    } else {
      this.data.set('option', value.toLowerCase());
      this.submitButtonTarget.disabled = false;
    }
  }

  setCustomOption(e) {
    this.data.set('newData', 'true');
    if (e.target.value.length > 3) {
      this.data.set('option', e.target.value);
      this.submitButtonTarget.disabled = false;
    }
  }

  setOptionMultiple(e) {
    this.data.set('newData', 'true');
    if (e.target.classList.contains('deactivated')) return;
    e.target.classList.toggle('selected');

    const value = e.target.dataset.value;
    const oldOptions = this.data.get('options');
    let optionsArray = [];

    if (
      this.data.get('options') === null ||
      this.data.get('options').length === 0
    ) {
      optionsArray.push(value);
    } else {
      optionsArray = oldOptions.split(',');
      optionsArray.includes(value)
        ? optionsArray.splice(optionsArray.indexOf(value), 1)
        : optionsArray.push(value);
    }
    this.data.set('options', optionsArray.join(','));

    optionsArray.length > 0
      ? (this.submitButtonTarget.disabled = false)
      : (this.submitButtonTarget.disabled = true);

    if (optionsArray.length >= 3) {
      this.pollButtonTargets.forEach(el => {
        if (!el.classList.contains('selected')) {
          el.classList.add('deactivated');
        }
      });
    } else {
      this.pollButtonTargets.forEach(el => {
        el.classList.remove('deactivated');
      });
    }
  }

  setInput(e) {
    this.data.set('newData', 'true');
    if (e.target.value.length > 3) {
      this.data.set('option', e.target.value);
      this.submitButtonTarget.disabled = false;
    }
  }

  async submit(e) {
    e.preventDefault();
    this.submitButtonTarget.disabled = true;

    let status;
    let newUserStep;
    let endOfOnboarding;

    if (this.data.get('newData') === 'true') {
      const value =
        this.templateTarget.value === 'multiple_options'
          ? this.data.get('options')
          : this.data.get('option');
      const url = `/polls/${this.pollIdValue}/submit`;
      const body = {
        value,
      };

      const result = JSON.parse(await fetchPut('post', url, body));
      status = result.status;
      newUserStep = result.new_user_step;
      endOfOnboarding = result.end_of_onboarding;
    } else {
      const url = `/polls/${this.pollIdValue}/validate-step`;

      const result = JSON.parse(await fetchPut('post', url));
      status = result.status;
      newUserStep = result.new_user_step;
      endOfOnboarding = result.end_of_onboarding;
    }

    console.log('before end of onboarding check');
    if (!!endOfOnboarding) {
      // Turbo.visit('/profiling/results', { action: 'replace' });
      Turbo.visit('/waiting-for-recommendations', { action: 'replace' });
      return;
    }
    console.log('after end of onboarding check');

    if (status === 'success') await this.displayPoll(newUserStep);
  }
}
