import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"]; // You'll use this target to identify each radio-button div

  selectOption(event) {
    // Remove selected class from all options
    this.optionTargets.forEach((option) => {
      option.classList.remove('selected');
    });

    // Add selected class to the clicked option
    event.currentTarget.classList.add('selected');

    // Check the associated radio button
    const radioButton = event.currentTarget.querySelector('input[type="radio"]');
    radioButton.checked = true;
  }
}