import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submit', 'radio'];

  connect() {
    this.disableSubmit();
  }

  change(event) {
    this.submitTarget.disabled = false;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.radioTargets.forEach((radioButton) => {
      if (radioButton.checked) {
        this.submitTarget.disabled = false;
        return true;
      }
    })
  }
}
