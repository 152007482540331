import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'currentTime',
    'pauseButton',
    'playButton',
    'playerContainer',
    'timeBar',
    'timeBarContainer',
    'totalTime',
    'volume',
    'volume0',
    'volume1',
    'volume2',
    'volume3',
    'volumeContainer',
    'volumeBar',
    'svg1',
    'svg2',
    'svg3',
  ];

  static values = {
    source: String,
  };

  formatDuration(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return [
      h,
      m > 9 ? m : h ? '0' + m : m || '0',
      s > 9 ? s.toFixed(0) : '0' + s.toFixed(0),
    ]
      .filter(a => a)
      .join(':');
  }

  connect() {
    const audio = new Audio(this.sourceValue);
    audio.crossOrigin = 'anonymous';
    this.audioValue = audio;
    this.audioContext = new AudioContext();
    const source = this.audioContext.createMediaElementSource(audio);
    this.analyser = this.audioContext.createAnalyser();
    source.connect(this.analyser);
    this.analyser.connect(this.audioContext.destination);
    this.analyser.fftSize = 256; // Vous pouvez ajuster cette valeur
    this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);

    this.currentTimeTarget.innerText = this.formatDuration(audio.currentTime);
    this.timeBarTarget.style.width = '0%';

    audio.onloadedmetadata = () => {
      this.totalTimeTarget.innerText = this.formatDuration(audio.duration);
      this.changeVolume(2, 50);
    };
    audio.addEventListener('timeupdate', () => {
      const progress = Number.parseInt(
        (audio.currentTime / audio.duration) * 100
      );

      if (progress === 100) {
        this.pauseButtonTarget.classList.add('hidden');
        this.playButtonTarget.classList.remove('hidden');
      }
      this.currentTimeTarget.innerText = this.formatDuration(audio.currentTime);
      this.timeBarTarget.style.width = `${progress}%`;
    });
  }

  disconnect() {
    this.pauseFile();
  }

  animateSVGs() {
    requestAnimationFrame(this.animateSVGs.bind(this));
    this.analyser.getByteFrequencyData(this.frequencyData);

    let avgFreq =
      this.frequencyData.reduce((a, b) => a + b, 0) / this.frequencyData.length;

    this.svg1Target.style.transform = `translate(-50%, -50%) scale(${1 + Math.sqrt(avgFreq) / 20
      })`;
    this.svg2Target.style.transform = `translate(-50%, -50%) scale(${1 + Math.pow(avgFreq, 2) / 50000
      })`;
    this.svg3Target.style.transform = `translate(-50%, -50%) scale(${1 + avgFreq / 400
      })`;
  }

  collapse() {
    this.playerContainerTarget.classList.toggle('collapsed');
  }

  playFile() {
    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume().then(() => {
        this.audioValue.play();
        this.animateSVGs();
      });
    } else {
      this.audioValue.play();
      this.animateSVGs();
    }

    this.playButtonTarget.classList.add('hidden');
    this.pauseButtonTarget.classList.remove('hidden');
  }

  pauseFile() {
    this.audioValue.pause();

    this.pauseButtonTarget.classList.add('hidden');
    this.playButtonTarget.classList.remove('hidden');
  }

  backward10sec() {
    this.audioValue.currentTime -= 10;
  }

  forward10sec() {
    this.audioValue.currentTime += 10;
  }

  setAudioLevel(level) {
    const volumes = [
      this.volume1Target,
      this.volume2Target,
      this.volume3Target,
    ];
    volumes.slice(level).forEach(item => {
      item.classList.add('hidden');
    });

    volumes.slice(0, level).forEach(item => {
      item.classList.remove('hidden');
    });
  }

  setAudioVolume(value) {
    this.audioValue.volume = value / 100;
  }

  changeVolume(level, value) {
    this.setAudioLevel(level);
    this.setAudioVolume(value);
    this.data.set('volumeValue', value);
    this.data.set('volumeLevel', level);
  }

  decideAudioVolume(volume) {
    if (volume <= 4) {
      this.changeVolume(0, volume);
    } else if (volume <= 36) {
      this.changeVolume(1, volume);
    } else if (volume <= 68) {
      this.changeVolume(2, volume);
    } else {
      this.changeVolume(3, volume);
    }
  }

  onChangeVolume(e) {
    const cursorPos = e.clientY;
    const volumeMinPos =
      this.volumeContainerTarget.getBoundingClientRect().bottom;
    const volumeMaxPos = this.volumeContainerTarget.getBoundingClientRect().top;
    const userChoice = cursorPos - volumeMinPos;
    const referenceWidth = volumeMaxPos - volumeMinPos;
    const volumePercentage = Number.parseInt(
      (userChoice / referenceWidth) * 100
    );

    this.decideAudioVolume(volumePercentage);

    this.volumeBarTarget.style.height = `${volumePercentage}%`;
  }

  onChangeTime(e) {
    const cursorPos = e.clientX;
    const timeMinPos = this.timeBarContainerTarget.getBoundingClientRect().left;
    const timeMaxPos =
      this.timeBarContainerTarget.getBoundingClientRect().right;
    const userChoice = cursorPos - timeMinPos;
    const referenceWidth = timeMaxPos - timeMinPos;
    const timePercentage = userChoice / referenceWidth;

    this.audioValue.currentTime = timePercentage * this.audioValue.duration;
    this.timeBarTarget.style.width = `${Number.parseInt(
      timePercentage * 100
    )}%`;
  }

  onMuteToggle() {
    if (this.data.get('volumeLevel') !== '0') {
      this.setAudioVolume(0);
      this.setAudioLevel(0);
      this.data.set('volumeLevel', 0);
      this.volumeBarTarget.style.height = `0%`;
    } else {
      this.decideAudioVolume(this.data.get('volumeValue'));
      this.volumeBarTarget.style.height = `${this.data.get('volumeValue')}%`;
    }
  }
}
