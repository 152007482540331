export const activate = targets => {
  targets.forEach(target => {
    target.classList.remove('disabled');
    target.classList.add('active');
  });
};

export const deactivate = targets => {
  targets.forEach(target => target.classList.remove('active'));
};

export const disable = targets => {
  targets.forEach(target => {
    target.classList.remove('active');
    target.classList.add('disabled');
  });
};

export const hide = targets => {
  targets.forEach(target => target.classList.add('hidden'));
};

export const makeFirst = target => target.classList.add('first-child');

export const show = targets => {
  targets.forEach(target => target.classList.remove('hidden'));
};

export const toggle = targets => {
  targets.forEach(target => target.classList.toggle('hidden'));
};
