export const dateFormatter = date => {
  return new Date(date * 1000).toLocaleDateString(undefined, {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  });
};

export const formatdDateSimple = timestamp => {
  const date = new Date(timestamp * 1000);
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getYear();

  return `${d < 10 ? `0${d}` : d}-${m}-${y}`;
};

export const formatDateWithLocale = (date, locale) => {
  if (locale === 'fr') {
    return new Date(date).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } else if (locale === 'en') {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
};

export const formatWithLinks = text =>
  `<div class="formatted-text">${text.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a href="$1" target="_blank">$1</a>'
  )}</div>`;

import I18n from '../react/shared/i18n';
export const dateFormatterDayMonth = date => {
  return new Date(date * 1000).toLocaleDateString(I18n.language, {
    day: '2-digit',
    month: 'short',
  });
};
