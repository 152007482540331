import BookingSupport from './booking_support_controller';
import flatpickr from 'flatpickr';
import { google, outlook, ics } from 'calendar-link';
import { flatpickrDefaultOptions, getTomorrow } from '../plugins/flatpickr';
import {
  activate,
  deactivate,
  disable,
  hide,
  makeFirst,
  show,
  toggle,
} from '../plugins/utils';
import {
  locales,
  bookingBreadcrumbs,
  bookingSteps,
} from '../plugins/constants';
import { formatDateWithLocale } from '../plugins/formatters';

export default class extends BookingSupport {
  static targets = [
    'allSessionsModal',
    'breadcrumb',
    'breadcrumbsDisplayer',
    'bookingAlternativeModal',
    'bookingError',
    'bookingErrorText',
    'bookingSuccess',
    'calendarLinksDisplayer',
    'calendarTitle',
    'component',
    'confirmationBreadcrumb',
    'confirmationStep',
    'dateBreadcrumb',
    'dateDisplayer',
    'dateStep',
    'durationBreadcrumb',
    'durationButton',
    'durationDisplayer',
    'durationStep',
    'durationSummaryItem',
    'enterpriseCreditError',
    'enterpriseNoCreditContent',
    'errorComponent',
    'flatpickr',
    'googleCalendar',
    'icsCalendar',
    'loader',
    'mainComponent',
    'modal',
    'noCreditModal',
    'outlookCalendar',
    'patientCreditError',
    'patientDisplayer',
    'patientId',
    'patientSummaryItem',
    'recapBreadcrumb',
    'recapDisplayer',
    'recapStep',
    'slot',
    'slotpickr',
    'slotStep',
    'therapistAvatar',
    'therapistDetails',
    'alternativeModalTherapistAvatar',
    'alternativeModalTherapistEmail',
    'alternativeModalTherapistPhone',
    'alternativeModalTherapistFullname',
    'therapistDisplayer',
    'therapistId',
    'therapistSummaryItem',
    'title',
    'titleBooking',
    'titleError',
    'titleReschedule',
    'titleSuccess',
    'timeDisplayer',
    'usersBreadcrumb',
    'userNoCreditContent',
    'usersStep',
  ];

  static values = {
    actionValue: String,
    calendar: String,
    userId: String,
    userLocale: String,
    userType: String,
    domainUrl: String,
    therapistId: String,
    calendarTitle: String,
  };

  displayCalendarLinks() {
    const event = {
      title: `${this.calendarTitleValue}`,
      start: `${this.data.get('date')} ${this.data.get('slot')}`,
      duration: [this.data.get('duration'), 'minute'],
      description: `${this.domainUrlValue}/start_session`,
    };

    const agendas = [
      { target: this.googleCalendarTarget, link: google(event) },
      { target: this.outlookCalendarTarget, link: outlook(event) },
      { target: this.icsCalendarTarget, link: ics(event) },
    ];

    // ?? agendas.map(agenda => {
    agendas.forEach(agenda => {
      agenda.target.href = agenda.link;
    });
  }

  getStep(type) {
    const dictionary = {
      users: this.usersStepTarget,
      duration: this.durationStepTarget,
      date: this.dateStepTarget,
      slot: this.slotStepTarget,
      confirmation: this.confirmationStepTarget,
      recap: this.recapStepTarget,
    };

    return dictionary[type];
  }

  getBreadcrumb(type) {
    const dictionary = {
      users: this.usersBreadcrumbTarget,
      duration: this.durationBreadcrumbTarget,
      date: this.dateBreadcrumbTarget,
      confirmation: this.confirmationBreadcrumbTarget,
      recap: this.recapBreadcrumbTarget,
    };

    return dictionary[type];
  }

  checkNextStep() {
    if (
      (this.userTypeValue === 'admin' &&
        this.data.get('patient').length > 0 &&
        this.data.get('therapist').length > 0) ||
      (this.userTypeValue === 'therapist' &&
        this.data.get('patient').length > 0)
    ) {
      hide([this.usersStepTarget]);
      show([this.durationStepTarget]);
      deactivate([this.usersBreadcrumbTarget]);
      activate([this.durationBreadcrumbTarget]);
    }
  }

  initFlatpickr(days, patient) {
    if (this.flatpickrTarget) {
      const options = {
        ...flatpickrDefaultOptions(patient),
        locale: locales[this.userLocaleValue],
        enable: days,
      };

      flatpickr(this.flatpickrTarget, options);
    }
  }

  reinitModal() {
    [
      'action',
      'patient',
      'therapist',
      'appointment',
      'duration',
      'date',
      'slot',
    ].forEach(item => this.data.set(item, ''));
    show([this.breadcrumbsDisplayerTarget, this.therapistAvatarTarget]);
    if (this.userTypeValue === 'patient')
      this.data.set('patient', this.userIdValue);
    if (this.userTypeValue === 'therapist')
      this.data.set('therapist', this.userIdValue);

    hide([
      ...this.titleTargets,
      this.usersStepTarget,
      this.durationStepTarget,
      this.dateStepTarget,
      this.slotStepTarget,
      this.confirmationStepTarget,
      this.recapStepTarget,
    ]);
  }

  closeModal() {
    hide([this.modalTarget, ...this.componentTargets]);
  }

  redirectToRoot() {
    this.closeModal();
    setTimeout(() => Turbo.visit('/'), 100);
  }

  closeNoCreditModal() {
    hide([this.noCreditModalTarget]);
  }

  showModal(components) {
    show([this.modalTarget, ...components]);
  }

  toggleMainComponent() {
    show([this.mainComponentTarget]);
  }

  toggleNewModal() {
    this.reinitModal();
    this.data.set('action', 'new');
    this.showModal([
      this.titleBookingTarget,
      this.mainComponentTarget,
      this.getStep(
        bookingSteps[this.userTypeValue][this.data.get('action')][0],
        'step'
      ),
    ]);
    this.manageBreadcrumbs();
  }

  toggleAllSessions() {
    this.allSessionsModalTarget.classList.toggle('hidden');
  }

  closeAllSessionsModal() {
    this.allSessionsModalTarget.classList.add('hidden');
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  displayNocreditModal(credit, enterpriseCredit) {
    const creditValue = Object.values(credit).reduce(
      (prev, key) => prev + key,
      0
    );

    const enterpriseCreditValue = Object.values(enterpriseCredit);

    hide([this.userNoCreditContentTarget]);
    hide([this.enterpriseNoCreditContentTarget]);
    if (creditValue < 1 || enterpriseCreditValue < 1) {
      if (creditValue < 1) {
        show([this.userNoCreditContentTarget]);
      } else {
        show([this.enterpriseNoCreditContentTarget]);
      }
      this.showModal([this.noCreditModalTarget]);
    }
  }

  async toggleModalWithSlot(e) {
    this.reinitModal();

    const target = e.target;
    const container = target.closest('.booking-slots');

    this.data.set('therapist', container.dataset.therapist);
    this.data.set('date', container.dataset.date);
    this.data.set('duration', container.dataset.duration);
    this.data.set('slot', target.dataset.slot);
    this.data.set('action', 'new');

    this.updateAlternativeModalTherapistDetails();

    this.displaySummary();

    if (this.userTypeValue === 'patient') {
      await this.displayTherapistAvatar(container.dataset.therapist);
    }

    this.showModal([
      this.titleBookingTarget,
      this.mainComponentTarget,
      this.confirmationStepTarget,
    ]);

    this.manageBreadcrumbs();

    hide([this.dateStepTarget, this.slotStepTarget]);
    show([this.confirmationStepTarget]);
    deactivate([this.durationBreadcrumbTarget]);
    activate([this.confirmationBreadcrumbTarget]);

    const credit = await this.fetchCredit(this.data.get('patient'));
    const enterpriseCredit = await this.fetchEnterpriseCredit(
      this.data.get('patient')
    );

    this.displayNocreditModal(credit, enterpriseCredit);
  }

  async toggleNewModalWithTherapist(e) {
    this.reinitModal();
    this.data.set('therapist', e.target.dataset.therapist);
    if (this.userTypeValue === 'patient')
      await this.displayTherapistAvatar(e.target.dataset.therapist);

    this.data.set('action', 'new');

    hide([this.noCreditModalTarget]);
    this.checkNextStep();
    this.updateAlternativeModalTherapistDetails();
    this.showModal([
      this.titleBookingTarget,
      this.mainComponentTarget,
      this.getStep(
        bookingSteps[this.userTypeValue][this.data.get('action')][0],
        'step'
      ),
    ]);
    this.manageBreadcrumbs();

    const credit = await this.fetchCredit(this.data.get('patient'));
    const enterpriseCredit = await this.fetchEnterpriseCredit(
      this.data.get('patient')
    );

    this.displayNocreditModal(credit, enterpriseCredit);
  }

  async toggleEditModal(e) {
    this.reinitModal();
    let target = e.target;
    while (target.classList.contains('action-btn') === false) {
      target = target.parentNode;
    }
    if (this.userTypeValue === 'patient')
      await this.displayTherapistAvatar(target.dataset.therapist);
    this.data.set('duration', target.dataset.duration);
    this.data.set('therapist', target.dataset.therapist);
    this.data.set('appointment', target.dataset.appointment);
    this.data.set('action', 'edit');
    this.displayDays();
    this.showModal([
      this.titleRescheduleTarget,
      this.mainComponentTarget,
      this.getStep(
        bookingSteps[this.userTypeValue][this.data.get('action')][0]
      ),
    ]);
    this.manageBreadcrumbs();
    this.updateAlternativeModalTherapistDetails();
  }

  toggleErrorModal() {
    this.reinitModal();
    toggle([this.errorComponentTarget]);
  }

  toggleBookingAlternativeModal() {
    toggle([this.bookingAlternativeModalTarget]);
  }

  async displayTherapistAvatar(id) {
    const therapistDetails = await this.fetchTherapistDetails(id);
    const avatarUrl = therapistDetails['avatar_url'];

    if (avatarUrl) {
      this.therapistAvatarTarget.src = avatarUrl;
      show([this.therapistDetailsTarget]);
    }
  }

  manageBreadcrumbs() {
    this.breadcrumbTargets.forEach(target => {
      target.classList.remove('first-child');
    });
    const activeBreadcrumbs = this.breadcrumbTargets.filter(target =>
      bookingBreadcrumbs[this.userTypeValue][this.data.get('action')].includes(
        target.id
      )
    );
    hide(this.breadcrumbTargets);
    show(activeBreadcrumbs);
    disable(activeBreadcrumbs);
    makeFirst(activeBreadcrumbs[0]);
    activate([activeBreadcrumbs[0]]);
  }

  changeView(e) {
    let target = e.target;
    while (target.classList.contains('breadcrumb-list-item') === false) {
      target = target.parentNode;
    }
    const targets = {
      users: [[this.usersStepTarget], this.usersBreadcrumbTarget],
      duration: [[this.durationStepTarget], this.durationBreadcrumbTarget],
      date: [
        [this.dateStepTarget, this.slotStepTarget],
        this.dateBreadcrumbTarget,
      ],
      confirmation: [
        [this.confirmationStepTarget],
        this.confirmationBreadcrumbTarget,
      ],
    };
    if (target.classList.contains('disabled')) return;
    [
      this.dateStepTarget,
      this.slotStepTarget,
      this.durationStepTarget,
      this.confirmationStepTarget,
      this.recapStepTarget,
      this.usersStepTarget,
    ].forEach(target => {
      if (target) hide([target]);
    });
    deactivate(this.breadcrumbTargets);
    show(targets[target.id][0]);
    activate([targets[target.id][1]]);
  }

  async setPatient() {
    this.data.set('patient', this.patientIdTarget.id);
    this.data.set('patientName', this.patientIdTarget.value);
    const credit = await this.fetchCredit(this.data.get('patient'));
    const creditValue = Object.values(credit).reduce(
      (prev, key) => prev + key,
      0
    );

    const enterpriseCredit = await this.fetchEnterpriseCredit(
      this.data.get('patient')
    );
    const enterpriseCreditValue = Object.values(enterpriseCredit);

    // TODO - This is clearly not the best way to do it and a bit overkill. The idea is that we may have to
    // discriminate between 40-60-credits but I'm not sure if and when. And anyway, this should be discussed
    // more globally because it might require a redesign on the model / back side. So let's keep it this way for now.

    if (creditValue > 0 && enterpriseCreditValue > 0) {
      hide([this.patientCreditErrorTarget]);
      this.checkNextStep();
    } else {
      if (creditValue < 1) {
        hide([this.enterpriseCreditErrorTarget]);
        show([this.patientCreditErrorTarget]);
      } else if (enterpriseCreditValue < 1) {
        hide([this.patientCreditErrorTarget]);
        show([this.enterpriseCreditErrorTarget]);
      }
    }
  }

  setTherapist() {
    this.data.set('therapist', this.therapistIdTarget.id);
    this.data.set('therapistName', this.therapistIdTarget.value);
    this.checkNextStep();
  }

  displaySummary() {
    if (this.data.get('therapistName')?.length > 0) {
      this.therapistDisplayerTarget.innerText = this.data.get('therapistName');
    } else {
      hide([this.therapistSummaryItemTarget]);
    }
    if (this.data.get('patientName')?.length > 0) {
      this.patientDisplayerTarget.innerText = this.data.get('patientName');
    } else {
      hide([this.patientSummaryItemTarget]);
    }
    if (this.data.get('duration')?.length > 0) {
      this.durationDisplayerTarget.innerText = `${this.data.get(
        'duration'
      )} min`;
    } else {
      hide([this.durationSummaryItemTarget]);
    }

    this.dateDisplayerTarget.innerText = formatDateWithLocale(
      this.data.get('date'),
      this.userLocaleValue
    );
    this.timeDisplayerTarget.innerText = this.data
      .get('slot')
      .match(/\d\d:\d\d/);
  }

  async updateAlternativeModalTherapistDetails() {
    const {
      full_name: fullName,
      phone,
      email,
      avatar_url: avatarUrl,
    } = await this.fetchTherapistDetails(this.data.get('therapist'));

    const title = this.alternativeModalTherapistFullnameTarget.innerText;
    this.alternativeModalTherapistFullnameTarget.innerText = title.replace(
      'therapistFullname',
      fullName
    );
    this.alternativeModalTherapistPhoneTarget.innerText = phone;
    this.alternativeModalTherapistEmailTarget.innerText = email;
    this.alternativeModalTherapistAvatarTarget.src = avatarUrl;
  }

  async displayDays() {
    toggle([this.loaderTarget]);
    const { days } = await this.fetchWorkDaysData(this.data.get('therapist'));
    this.initFlatpickr(days, this.userTypeValue === 'patient');
    toggle([this.loaderTarget]);
  }

  async onSelectDuration(e) {
    hide([this.durationStepTarget]);
    this.data.set('duration', e.target.id);
    this.data.set('date', '');
    this.data.set('slot', '');

    await this.displayDays();
    this.durationButtonTargets.forEach(el =>
      el.classList.remove('selected-feature')
    );
    e.target.classList.add('selected-feature');

    show([this.dateStepTarget]);
    deactivate([this.durationBreadcrumbTarget]);
    activate([this.dateBreadcrumbTarget]);
    disable([this.confirmationBreadcrumbTarget]);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async onSelectDate(e) {
    hide([this.slotStepTarget]);
    this.data.set('date', e.target.value);

    toggle([this.loaderTarget]);
    const { slots } = await this.fetchTimeSlots(
      this.data.get('therapist'),
      this.data.get('date'),
      this.data.get('duration')
    );
    this.slotpickrTarget.innerHTML = slots
      .map(slot => {
        if (
          true
          // this.userTypeValue !== 'patient'
          // || Number.parseInt(slot.split(':')[0]) >= 12
          // || new Date(this.data.get('date')) > getTomorrow()
        ) {
          return `<button class="feature-selector"
                          data-action="click->booking#onSelectSlot"
                          data-booking-target="slot"
                          id="${slot}">${slot.match(/\d\d:\d\d/)}
                  </button>`;
        }
      })
      .join('');
    toggle([this.loaderTarget]);
    show([this.slotStepTarget]);
  }

  onSelectSlot(e) {
    this.slotTargets.forEach(el => el.classList.remove('selected-feature'));
    e.target.classList.add('selected-feature');

    this.data.set('slot', e.target.id);
    this.displaySummary();
    hide([this.dateStepTarget, this.slotStepTarget]);
    show([this.confirmationStepTarget]);
    deactivate([this.dateBreadcrumbTarget]);
    activate([this.confirmationBreadcrumbTarget]);

    if (!(this.userTypeValue === 'patient')) {
      hide([this.therapistDetailsTarget]);
    }
  }

  async onSubmit() {
    hide([this.confirmationStepTarget]);
    toggle([this.loaderTarget]);

    const userAction = this.data.get('action');

    let response;
    if (this.data.get('action') === 'new') {
      response = await this.postBooking(
        this.data.get('patient'),
        this.data.get('therapist'),
        this.data.get('date'),
        this.data.get('slot'),
        this.data.get('duration')
      );
    } else if (this.data.get('action') === 'edit') {
      response = await this.patchBooking(
        this.data.get('appointment'),
        this.data.get('date'),
        this.data.get('slot')
      );
    }
    hide([
      this.breadcrumbsDisplayerTarget,
      this.confirmationStepTarget,
      this.loaderTarget,
      this.therapistAvatarTarget,
    ]);
    show([this.recapStepTarget]);

    if (
      response.redirection_url !== undefined &&
      response.redirection_url.includes('/orders/appointment/')
    ) {
      Turbo.visit(response.redirection_url);
    }

    hide([this.titleBookingTarget, this.titleRescheduleTarget]);

    if (response.success) {
      if (this.userTypeValue === 'patient') {
        this.displayCalendarLinks();
        show([this.bookingSuccessTarget, this.titleSuccessTarget]);
      } else {
        Turbo.visit('/');
      }
    } else {
      show([this.bookingErrorTarget, this.titleErrorTarget]);
    }
  }
}
