import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'motiveButton',
    'motiveInput',
    'reorientationLabelButton',
    'reorientationLabelInput',
    'rateButton',
    'ratingInput',
    'situationButton',
    'situationInput',
    'situationNotice',
    'situationNoticeList',
    'submit',
  ];

  rate(e) {
    e.preventDefault();
    this.rateButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });
    this.ratingInputTarget.value = e.target.id;
    e.target.classList.add('selected');
  }

  setMotive(e) {
    e.preventDefault();
    this.motiveInputTarget.value = e.target.id;
    this.motiveButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });
    e.target.classList.add('selected');
  }

  setReorientationLabel(e) {
    e.preventDefault();
    this.reorientationLabelInputTarget.value = e.target.id;
    this.reorientationLabelButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });
    e.target.classList.add('selected');
  }

  manageNotices(id) {
    const noticeTarget = document.getElementById(
      id.replace('button', 'notice')
    );

    this.situationNoticeTargets.forEach(el => {
      el.classList.add('hol-hidden');
    });
    noticeTarget.classList.remove('hol-hidden');
    this.situationNoticeListTarget.classList.remove('hol-hidden');
  }

  setSituation(e) {
    e.preventDefault();
    this.situationInputTarget.value = e.target.id.split('-')[1];
    this.situationButtonTargets.forEach(el => {
      el.classList.remove('selected');
    });
    e.target.classList.add('selected');

    this.manageNotices(e.target.id);
  }
}
