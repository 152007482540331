import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['wrapper', 'itemsContainer', 'nextButton', 'previousButton'];

  connect() {
    this.updateScrollButtons();
    this.scrollToActiveTab();
  }

  updateScrollButtons() {
    const wrapper = this.wrapperTarget;
    const prevBtn = this.previousButtonTarget;
    const nextBtn = this.nextButtonTarget;


    const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;
    wrapper.scrollLeft > 0 ? this.showBtn(prevBtn) : this.hideBtn(prevBtn);
    wrapper.scrollLeft < maxScrollLeft ? this.showBtn(nextBtn) : this.hideBtn(nextBtn);
  }

  scrollToActiveTab() {
    const activeTab = this.wrapperTarget.querySelector(".active");

    if (activeTab) {
      activeTab.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "center",
      });
    }
  }

  scrollLeft(e) {
    e.preventDefault();
    e.stopPropagation();
    this.wrapperTarget.scrollBy({ left: -200, behavior: 'smooth' });
    this.updateScrollButtons();
  }

  scrollRight(e) {
    e.preventDefault();
    e.stopPropagation();
    this.wrapperTarget.scrollBy({ left: 200, behavior: 'smooth' });
    this.updateScrollButtons();
  }


  showBtn(button) {
    button.classList.remove('hol-hidden');
  }

  hideBtn(button) {
    button.classList.add('hol-hidden');
  }


}
