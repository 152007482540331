import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "header"];

  fix() {
    if (window.scrollY > 100) {
      this.headerTarget.classList.add("displayed-title");
      this.contentTarget.classList.add("displayed-title");
    } else {
      this.headerTarget.classList.remove("displayed-title");
      this.contentTarget.classList.remove("displayed-title");
    }
  }
}
