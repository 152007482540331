import { Controller } from 'stimulus';
import { fetchGet, fetchPut } from '../plugins/api';

export default class extends Controller {
  async fetchTherapistDetails(therapist) {
    const url = `/therapists/${therapist}`;
    return JSON.parse(await fetchGet(url));
  }

  async fetchCredit(patient) {
    const url = `/patients/${patient}/credit`;

    return JSON.parse(await fetchGet(url));
  }

  async fetchEnterpriseCredit(patient) {
    const url = `/patients/${patient}/enterprise_credit`;

    return JSON.parse(await fetchGet(url));
  }

  async fetchWorkDaysData(therapist) {
    const url = `/therapists/${therapist}/scheduled-days`;

    return JSON.parse(await fetchGet(url));
  }

  async fetchTimeSlots(therapist, date, duration) {
    const url = `/therapists/${therapist}/available-slots/${date}/`;
    const body = {
      duration,
    };
    return JSON.parse(await fetchGet(url, body));
  }

  async postBooking(user, therapist, date, slot, duration) {
    const url = `/appointments`;
    const body = {
      patient_id: user,
      therapist_id: therapist,
      start_time: `${date} ${slot}`,
      duration: duration,
    };

    return JSON.parse(await fetchPut('post', url, body));
  }

  async patchBooking(appointment, date, slot) {
    const url = `/appointments/${appointment}`;
    const body = {
      start_time: `${date} ${slot}`,
    };

    return JSON.parse(await fetchPut('patch', url, body));
  }
}
