import { Controller } from 'stimulus';

export default class extends Controller {

  open(event) {
    event.preventDefault();
    $crisp.push(["do", "chat:show"]);
    $crisp.push(["do", "chat:open"]);
  }

}
