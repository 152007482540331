import { Controller } from 'stimulus';
import { fetchGet, fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = [
    'emptyStar',
    'fullStar',
    'ratedMessageContainer',
    'ratingStarsContainer',
    'ratingBox',
    'title',
  ];
  static values = {
    user: String,
    item: String,
  };

  async connect() {
    const url = '/toolboxes/rating';
    const params = {
      user_id: this.userValue,
      toolbox_id: this.itemValue,
    };
    const { result } = JSON.parse(await fetchGet(url, params));

    if (result) {
      this.data.set('recordedRating', result);
      this.ratingBoxTarget.classList.add('hidden');
    }
  }

  getIdNumber(id) {
    return Number.parseInt(id.split('-')[1]);
  }

  async rate(e) {
    const targetId = e.target.id;
    const id = this.getIdNumber(targetId);

    this.fillStars(id);
    const url = '/toolboxes/rating';
    const body = {
      user_id: this.userValue,
      toolbox_id: this.itemValue,
      value: id + 1,
    };

    const { result } = JSON.parse(await fetchPut('post', url, body));
    if (result) {
      this.data.set('recordedRating', result);
      this.ratingStarsContainerTarget.classList.add('hidden');
      this.titleTarget.classList.add('hidden');
      this.ratedMessageContainerTarget.classList.remove('hidden');
    }
  }

  fillStars(id) {
    this.emptyStarTargets.forEach(star => {
      if (this.getIdNumber(star.id) <= id) {
        star.classList.add('hidden');
      } else {
        star.classList.remove('hidden');
      }
    });
    this.fullStarTargets.forEach(star => {
      if (this.getIdNumber(star.id) <= id) {
        star.classList.remove('hidden');
      } else {
        star.classList.add('hidden');
      }
    });
  }
}
