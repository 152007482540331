import { Controller } from "stimulus";

export default class extends Controller {
  trigger(event) {
    let target = event.target;
    while (!target.classList.contains('dimension-item')) {
      target = target.parentNode;
    }
    
    const triggers = document.querySelectorAll('.dimension-item');
    const dimensionTargets = document.querySelectorAll('.dimension-details-item');
    const dimensionTarget = document.getElementById(target.id.replace('trigger', 'target'));

    triggers.forEach(trigger => {
      trigger.classList.remove('selected');
    });
    target.classList.add('selected');
    dimensionTargets.forEach(target => {
      target.classList.add('hidden');
    });
    dimensionTarget.classList.remove('hidden');
  }
}