import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';
import Cookies from 'js-cookie';

// Track Click Events
//
// to clickable element you want to track (mandatory) :
// add stimulus controller: data-controller="track-event"
// add stimulus action: data-action="click->track-event#create"
// add a name to the event: data-track-event-name-value="makeBooking"
//
// if you want to link two events together (funnel analysis), you need to :
// add action value "set" to the first event: data-track-event-action-value="set"
// add action value "get" to the second event: data-track-event-action-value="get"
// add the same origin value: data-track-event-origin-value="fullBooking"

// (optional) add any properties: data-track-event-properties-value="<%= { user_type: current_user.type, category: 'appointment' }.to_json %>"
//
// Holivia convention :
// - name => event uniq identifier: 'makeBooking'
// - category : group event by type. Ex: 'appointment'
//
// Ex :  <button
//    class="btn-main-orange"
//    data-controller='track-event'
//    data-action="click->booking#toggleNewModalWithTherapist click->track-event#create"
//    data-track-event-action-value="set"
//    data-track-event-name-value="makeBooking"
//    data-track-event-origin-value="fullBooking"
//    data-track-event-properties-value="<%= { user_type: current_user.type, category: 'appointment' }.to_json %>"
//    data-therapist="<%= @therapist.id %>"
//    type="button"
//     >

export default class extends Controller {
  static values = {
    action: String,
    properties: Object,
    name: String,
    origin: String,
  };
  create() {
    const name = this.nameValue;

    // name value is mandatory
    if (name === '') {
      return console.log("track event name missing, can't create event");
    }

    let origin = this.originValue;
    let params = {};
    if (this.actionValue === 'set') {
      Cookies.set('track-event-origin', origin);
    }

    if (this.actionValue === 'get') {
      origin = Cookies.get('track-event-origin');
    }

    params = { origin: origin, ...this.propertiesValue };
    ahoy.track(name, params);
  }
}
