import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['password'];

  togglePasswordDisplay(e) {
    if (this.passwordTarget.getAttribute('type') == 'password') {
      this.passwordTarget.setAttribute('type', 'text');
    } else {
      this.passwordTarget.setAttribute('type', 'password');
    }
  }
}
