import { Controller } from 'stimulus';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['output'];
  static values = { url: String };

  connect() {
    const url = this.urlValue;
    this.fetchContent(url);
  }

  async fetchContent(url) {
    const request = new FetchRequest('get', url);
    const response = await request.perform();

    if (response.ok) {
      const content = await response.text;
      this.outputTarget.innerHTML = content;
    }

    return response.statusCode;
  }
}
