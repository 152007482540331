import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'pictoPrev', 'pictoNext', 'scrollBar'];

  connect() {
    this.managePictosAtStart();
  }

  managePictosAtStart() {
    if (this.contentTarget.scrollWidth <= this.contentTarget.offsetWidth) {
      this.scrollBarTarget.classList.add('hidden');
    }
    this.pictoPrevTarget.classList.add('faded');
  }

  handleWheelScroll() {
    this.contentTarget.scrollLeft === 0
      ? this.pictoPrevTarget.classList.add('faded')
      : this.pictoPrevTarget.classList.remove('faded');
    this.contentTarget.scrollWidth ===
    this.contentTarget.clientWidth + this.contentTarget.scrollLeft
      ? this.pictoNextTarget.classList.add('faded')
      : this.pictoNextTarget.classList.remove('faded');
  }

  scrollBackward() {
    this.contentTarget.scrollTo({
      left: this.contentTarget.scrollLeft - this.contentTarget.offsetWidth - 16,
      behavior: 'smooth',
    });
  }

  scrollForward() {
    this.contentTarget.scrollTo({
      left: this.contentTarget.scrollLeft + this.contentTarget.offsetWidth + 16,
      behavior: 'smooth',
    });
  }
}
