import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"]

  disableItems(event) {
    this.itemTargets.forEach((item) => {
      if (item.contains(event.target)) return;

      item.disabled = !item.disabled;
    });
  }
}
