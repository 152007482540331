import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['subnav', 'backdrop'];

  connect() { }

  open(event) {
    event.preventDefault();
    console.log('open more');
    this.subnavTarget.classList.add('open');
    this.backdropTarget.classList.add('open');
  }

  close(event) {
    event.preventDefault();
    console.log('close more');
    this.subnavTarget.classList.remove('open');
    this.backdropTarget.classList.remove('open');
  }

}
