import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['text'];

  toggle(event) {
    console.log('toggle', event);
    this.textTarget.classList.toggle('show');
  }
}
