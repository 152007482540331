import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['bar', 'barThumb', 'content'];

  shouldHandleContentScroll = true;

  connect = () => {
    this.manageBarAtStart();
    this.contentTarget.addEventListener('scroll', this.handleContentScroll);
  };

  disconnect = () => {
    this.contentTarget.removeEventListener('scroll', this.handleContentScroll);
  };

  manageBarAtStart = () => {
    this.contentWidth = this.contentTarget.scrollWidth;
    this.contentVisibleWidth = this.contentTarget.offsetWidth;

    if (this.contentWidth > this.contentVisibleWidth) {
      this.barTarget.classList.remove('hidden');
      this.barWidth = this.barTarget.offsetWidth;
      this.thumbWidth = this.barThumbTarget.offsetWidth;
      this.maxScrollLeft = this.contentWidth - this.contentVisibleWidth;

      const contentVisibleRatio = this.contentVisibleWidth / this.contentWidth;
      this.numSlots = Math.max(2, Math.ceil(1 / contentVisibleRatio));

      this.slotWidth = this.barWidth / this.numSlots;
      this.barThumbTarget.style.width = `${100 / this.numSlots}%`;
    }
  };

  handleClick = event => {
    this.shouldHandleContentScroll = false;
    const clickX = event.clientX - this.barTarget.getBoundingClientRect().left;

    const clickedSlot = Math.floor(clickX / this.slotWidth);
    const scrollLeft = (this.maxScrollLeft / (this.numSlots - 1)) * clickedSlot;

    this.moveBarThumb(this.slotWidth * clickedSlot);
    this.moveContent(scrollLeft, 'smooth');
  };

  handleContentScroll = () => {
    if (this.shouldHandleContentScroll) {
      const visibleContentRatio =
        this.contentTarget.scrollLeft / this.maxScrollLeft;

      const currentSlot = Math.floor(visibleContentRatio * (this.numSlots - 1));

      this.moveBarThumb(this.slotWidth * currentSlot);
    }
  };

  onContentScrollEnd = () => {
    this.shouldHandleContentScroll = true;
  };

  moveContent = (position, behavior, callback) => {
    this.contentTarget.scrollTo({
      left: position,
      behavior: behavior,
    });
  };

  moveBarThumb = position => {
    this.barThumbTarget.style.transform = `translateX(${position}px)`;
  };
}
