import { English } from 'flatpickr/dist/l10n/default.js';
import { French } from 'flatpickr/dist/l10n/fr.js';

export const locales = {
  en: English,
  fr: French,
};

export const bookingSteps = {
  admin: {
    new: ['users', 'duration', 'date', 'slot', 'confirmation', 'recap'],
    edit: ['date', 'slot', 'confirmation', 'recap'],
  },
  therapist: {
    new: ['users', 'duration', 'date', 'slot', 'confirmation', 'recap'],
    edit: ['date', 'slot', 'confirmation', 'recap'],
  },
  patient: {
    new: ['duration', 'date', 'slot', 'confirmation', 'recap'],
    edit: ['date', 'slot', 'confirmation', 'recap'],
  },
};

export const bookingBreadcrumbs = {
  admin: {
    new: ['users', 'duration', 'date', 'confirmation', 'recap'],
    edit: ['date', 'confirmation', 'recap'],
  },
  therapist: {
    new: ['users', 'duration', 'date', 'confirmation', 'recap'],
    edit: ['date', 'confirmation', 'recap'],
  },
  patient: {
    new: ['duration', 'date', 'confirmation', 'recap'],
    edit: ['date', 'confirmation', 'recap'],
  },
};
