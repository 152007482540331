import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['mainLoader'];
  static values = { with: String };

  handle(event) {
    const button = event.currentTarget;

    if (button.dataset.disabled) {
      event.preventDefault();
    } else {
      this.mainLoaderTarget.classList.remove('hidden');

      button.dataset.disabled = true;
      if (this.hasWithValue) {
        button.innerHTML = this.withValue;
        button.value = this.withValue;
      }
    }
  }
}
