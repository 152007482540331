import { Controller } from 'stimulus';
import { fetchGet, fetchPut } from '../plugins/api';

import flatpickr from 'flatpickr';
import { flatpickrDefaultOptions } from '../plugins/flatpickr';
import { locales } from '../plugins/constants';

export const hide = targets => {
  targets.forEach(target => target.classList.add('hol-hidden'));
};

export const show = targets => {
  targets.forEach(target => target.classList.remove('hol-hidden'));
};

export const toggle = targets => {
  targets.forEach(target => target.classList.toggle('hol-hidden'));
};

export default class extends Controller {
  static targets = [
    'container',
    'flatpickr',
    'slots',
    'slotsWrapper',
    'startTimeField',
    'slot',
    'dateLoader',
    'timeLoader',
  ];

  static values = {
    therapistId: String,
    userLocale: String,
    duration: String,
    startTime: String,
  };

  async connect() {
    this.removeExistingCalendars(); // fix back button bug

    const { days } = await this.fetchWorkDaysData();
    const current_date = this.startTimeValue.split(' ')[0];

    show([this.dateLoaderTarget]);
    this.initFlatpickr(days, current_date);
    hide([this.dateLoaderTarget]);

    if (current_date) {
      this.data.set('date', current_date);
      await this.showSlotsForDate(current_date);
      const current_slot = this.startTimeValue.split(' ')[1];

      if (current_slot) {
        const slot = document.getElementById(current_slot);
        if (slot) {
          this.selectSlot(slot);
        }
      }
    }
  }

  // avoid having multiple calendars on same page
  removeExistingCalendars() {
    const existingCalendars = this.containerTarget.querySelectorAll(
      '.flatpickr-calendar'
    );
    existingCalendars.forEach(el => {
      el.remove();
    });
  }

  initFlatpickr(days, current_date) {
    const minDate = days[0];
    const minDateYear = minDate.split('-')[0];
    const minDateMonth = minDate.split('-')[1];

    if (this.flatpickrTarget) {
      const options = {
        minDate: minDate,
        inline: true,
        time_24hr: true,
        locale: locales[this.userLocaleValue],
        enable: days,
      };

      const fp = flatpickr(this.flatpickrTarget, options);

      fp.changeYear(parseInt(minDateYear));
      fp.changeMonth(parseInt(minDateMonth) - 1, false);
      if (current_date) {
        fp.setDate(current_date);
      }
    }
  }

  async fetchWorkDaysData() {
    const url = `/therapists/${this.therapistIdValue}/available-days`;
    const body = { duration: this.durationValue };

    const response = await fetchGet(url, body);

    return JSON.parse(response);
  }

  async fetchTimeSlots(date) {
    const url = `/therapists/${this.therapistIdValue}/available-slots/${date}`;
    const body = { duration: this.durationValue };
    return JSON.parse(await fetchGet(url, body));
  }

  async onSelectDate(e) {
    const date = e.target.value;
    this.data.set('date', date);
    this.showSlotsForDate(date);
  }

  async showSlotsForDate(date) {
    hide([this.slotsWrapperTarget]);
    hide([this.slotsTarget]);
    show([this.timeLoaderTarget]);
    const { slots } = await this.fetchTimeSlots(date);
    const slots_buttons = slots
      .map(slot => {
        {
          return `<span class="slot-button btn-slot"
    data-action="click->appointment-slot#onSelectSlot"
    data-appointment-slot-target="slot"
    id="${slot}">${slot.match(/\d\d:\d\d/)}
    </span>`;
        }
      })
      .join('');
    this.slotsTarget.innerHTML = `<div class="slots-buttons">${slots_buttons}</div>`;
    hide([this.timeLoaderTarget]);
    show([this.slotsWrapperTarget]);
    show([this.slotsTarget]);
  }

  async onSelectSlot(e) {
    this.data.set('slot', e.target.id);

    this.selectSlot(e.target);

    const date = this.data.get('date');
    const slot = e.target.id;
    const start_time = `${date} ${slot}`;
    this.startTimeFieldTarget.value = start_time;
  }

  selectSlot(element) {
    show([this.slotsWrapperTarget]);
    show([this.slotsTarget]);
    this.slotTargets.forEach(el => el.classList.remove('selected-slot'));
    element.classList.add('selected-slot');
  }
}
