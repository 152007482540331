import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["landbotProgram", "errorLandbotProgram", "myLandbot"]

  connect() {
    this.loadScript('https://static.landbot.io/landbot-3/landbot-3.0.0.js', () => {
      this.initializeLandbot();
    });
  }

  loadScript(url, callback) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Most browsers
    script.onload = callback;

    // IE 6 & 7
    script.onreadystatechange = function() {
      if (this.readyState == 'complete') {
        callback();
      }
    }

    document.getElementsByTagName('head')[0].appendChild(script);
  }


  initializeLandbot() {
    if (typeof Landbot !== 'undefined') {
      var myLandbot = new Landbot.Container({
        container: '#' + this.myLandbotTarget.id,
        configUrl: this.data.get('configUrl'),
        customData: this.getCustomData()
      });
    } else {
      this.landbotProgramTarget.classList.add('hidden');
      this.errorLandbotProgramTarget.classList.remove('hidden');
    }
  }

  getCustomData() {
    let customData = {
      enterprise: this.data.get('enterprise'),
      environment: this.data.get('environment'),
      user_id: this.data.get('userId'),
      email: this.data.has('email') ? this.data.get('email') : undefined
    }

    if (this.data.get('type') === 'program') {
      customData.ongoing_program_id = this.data.get('ongoingProgramId');
      customData.program_id = this.data.get('programId');
    } else if (this.data.get('type') === 'corporate_survey') {
      customData.survey_id = this.data.get('surveyId');
      customData.domain = this.data.get('domain');
    } else if (this.data.get('type') === 'toolbox') {
      customData.content_id = this.data.get('contentId');
      customData.domain = this.data.get('domain');
    }else if (this.data.get('type') === 'chat_bot') {
      customData.chat_bot_id = this.data.get('chatBotId');
      customData.domain = this.data.get('domain');
    }

    return customData;
  }
}