import { Controller } from 'stimulus';
import { fetchGet } from '../plugins/api';

export default class extends Controller {
  static targets = ['input', 'entries', 'toggleContent'];
  static values = {
    type: String,
  };

  async connect() {
    const result = await fetchGet(`/${this.typeValue}s-json`, {});
    this.data.set('listItems', result);
    const entries = JSON.parse(result);
    this.entriesTarget.innerHTML = entries
      .map(
        entry =>
          `<li id="${entry.id}" data-action="click->searchable-select#selectId">${entry.first_name} ${entry.last_name}</li>`
      )
      .join('');
  }

  selectId(event) {
    this.data.set(`${this.typeValue}Id`, event.target.id);
    this.inputTarget.value = event.target.innerText;
    this.inputTarget.id = event.target.id;
    this.toggleList();
    window.dispatchEvent(new CustomEvent(`selected-${this.typeValue}`));
  }

  async filterList(event) {
    let listItems = JSON.parse(this.data.get('listItems'));
    if (this.entriesTarget.classList.contains('hidden')) this.toggleList();
    listItems = listItems.filter(
      item =>
        item.first_name.toLowerCase().includes(event.target.value) ||
        item.last_name.toLowerCase().includes(event.target.value) ||
        `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}`.includes(
          event.target.value
        ) ||
        `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()}`.includes(
          event.target.value
        )
    );
    this.entriesTarget.innerHTML = listItems
      .map(
        entry =>
          `<li id="${entry.id}" data-action="click->searchable-select#selectId">${entry.first_name} ${entry.last_name}</li>`
      )
      .join('');
    // TODO IMPLEMENT REAL SEARCH
    // const entries = JSON.parse(
    //   await fetchGet(`/${this.typeValue}s`, { search: event.target.value })
    // );
  }

  toggleList() {
    this.toggleContentTargets.forEach(el => el.classList.toggle('hidden'));
  }
}
