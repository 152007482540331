import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  connect() {
    console.log('Hello, copy!');
  }

  copy(event) {
    event.preventDefault();

    const content = this.contentTarget.textContent;
    navigator.clipboard.writeText(content).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

}
