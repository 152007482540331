import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'pictoPrev', 'pictoNext', 'scrollBar'];

  connect() { }

  goToAnchor(event) {
    const id = event.target.dataset.anchorIdValue;

    const element = document.getElementById(id);

    const offset = 20;

    if (element) {
      window.scrollTo({
        top: element.offsetTop - offset,
        behavior: 'smooth',
      });
    }
  }
}
