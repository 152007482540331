import { Application } from 'stimulus';
import AllAppointmentsController from '../all_sessions_controller';
import AnchorController from '../anchor_controller';
import AppointmentRatingsController from '../appointment_ratings_controller';
import AppointmentSlotController from '../appointment_slot_controller';
import AudioPlayerController from '../audio_player_controller';
import AutocompleteController from '../autocomplete_controller';
import BookingController from '../booking_controller';
import CalendarController from '../calendar_controller';
import ChartController from '../chart_controller';
import ContentSearchFormController from '../content_search_form_controller';
import ContentsRatingsController from '../contents_ratings_controller';
import CopyController from '../copy_controller';
import DatepickerController from '../datepicker_controller';
import DeviseController from '../devise_controller';
import DisableButtonController from '../disable_button_controller';
import DisableItemController from '../disable_item_controller';
import PreventDefaultController from '../prevent_default_controller';
import DrawerController from '../drawer_controller';
import HolFiltersController from '../hol_filters_controller';
import FiltersController from '../filters_controller';
import FixHeaderController from '../fix-header_controller';
import InjectController from '../inject_controller';
import HolModalController from '../hol_modal_controller';
import LandbotController from '../landbot_controller';
import LazyLoadController from '../lazy_load_controller';
import NavbarController from '../navbar_controller';
import OnboardingController from '../onboarding_controller';
import EmployeeOnboardingController from '../employee_onboarding_controller';
import PhoneController from '../phone_controller';
import PollingController from '../polling_controller';
import PreviewCallController from '../preview_call_controller';
import ProfilingResultsController from '../profiling_results_controller';
import RadioController from '../radio_controller';
import ScrollController from '../scroll_controller';
import ScrollBarController from '../scroll-bar_controller';
import SearchableSelectController from '../searchable_select_controller';
import SetIssueController from '../set_issue_controller';
import SubmitOnSelectController from '../submit_on_select_controller';
import SupportController from '../support_controller';
import SwitchButtonController from '../switch_button_controller';
import TabsController from '../tabs_controller';
import ToggleController from '../toggle_controller';
import ToolboxRatingsController from '../toolbox_ratings_controller';
import TooltipController from '../tooltip_controller';
import TopbarController from '../topbar_controller';
import TrackEventController from '../track_event_controller';
import UnsupportedBrowserController from '../unsupported_browser_controller';
import VideoTestController from '../video_test_controller';
import WaitAndRedirectController from '../wait_and_redirect_controller';
import ShowContentController from '../show_content_controller';
const application = Application.start();

application.register('appointment-ratings', AppointmentRatingsController);
application.register('appointment-slot', AppointmentSlotController);
application.register('all_sessions', AllAppointmentsController);
application.register('anchor', AnchorController);
application.register('audio-player', AudioPlayerController);
application.register('autocomplete', AutocompleteController);
application.register('booking', BookingController);
application.register('calendar', CalendarController);
application.register('chart', ChartController);
application.register('contents-ratings', ContentsRatingsController);
application.register('content-search-form', ContentSearchFormController);
application.register('copy', CopyController);
application.register('datepicker', DatepickerController);
application.register('devise', DeviseController);
application.register('disable-button', DisableButtonController);
application.register('disable-item', DisableItemController);
application.register('prevent-default', PreventDefaultController);
application.register('drawer', DrawerController);
application.register('hol-filters', HolFiltersController);
application.register('filters', FiltersController);
application.register('fix-header', FixHeaderController);
application.register('inject', InjectController);
application.register('hol-modal', HolModalController);
application.register('landbot', LandbotController);
application.register('lazy-load', LazyLoadController);
application.register('navbar', NavbarController);
application.register('onboarding', OnboardingController);
application.register('employee-onboarding', EmployeeOnboardingController);
application.register('phone', PhoneController);
application.register('polling', PollingController);
application.register('preview-call', PreviewCallController);
application.register('profiling-results', ProfilingResultsController);
application.register('radio', RadioController);
application.register('scroll', ScrollController);
application.register('scroll-bar', ScrollBarController);
application.register('searchable-select', SearchableSelectController);
application.register('set-issue', SetIssueController);
application.register('submit-on-select', SubmitOnSelectController);
application.register('support', SupportController);
application.register('switch-button', SwitchButtonController);
application.register('tabs', TabsController);
application.register('toggle', ToggleController);
application.register('tooltip', TooltipController);
application.register('toolbox-ratings', ToolboxRatingsController);
application.register('topbar', TopbarController);
application.register('track-event', TrackEventController);
application.register('unsupported-browser', UnsupportedBrowserController);
application.register('video-test', VideoTestController);
application.register('wait-and-redirect', WaitAndRedirectController);
application.register('show-content', ShowContentController);
