import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['wrapper', 'filter', 'dropdown', 'more', 'moreTextContainer', 'shownedFilters'];
  static values = { moreText: String, allItemsText: String, allItemsIconPath: String }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.checkOverflow();
    this.checkDropdownSelected();
    this.positionDropdown();
  }

  checkOverflow() {
    const wrapper = this.wrapperTarget;
    const filters = this.filterTargets;

    let totalWidth = 80;
    const hiddenFilters = [];

    filters.forEach((filter) => {
      totalWidth += filter.offsetWidth + 40;
      if (totalWidth > wrapper.offsetWidth) {
        hiddenFilters.push(filter);
      }
    });

    this.updateDropdown(hiddenFilters);
    this.checkDropdownSelected();
    this.positionDropdown();
  }

  updateDropdown(hiddenFilters) {
    const dropdown = this.dropdownTarget;

    hiddenFilters.forEach((filter) => {
      dropdown.appendChild(filter);
    });

    if (hiddenFilters.length > 0) {
      dropdown.hidden = false;
    } else {
      dropdown.hidden = true;
    }
  }

  openSelect(e) {
    e.preventDefault();
    this.dropdownTarget.classList.toggle("hol-hidden");
    this.positionDropdown();
    document.addEventListener("click", this.handleClickOutside);
  }

  positionDropdown() {
    const more = this.moreTarget;
    const wrapper = this.wrapperTarget;
    const moreRect = more.getBoundingClientRect();
    const wrapperRect = wrapper.getBoundingClientRect();
    const top = moreRect.top - wrapperRect.top + more.offsetHeight + 10;
    const left = moreRect.left - wrapperRect.left;
    this.dropdownTarget.style.top = `${top}px`;
    this.dropdownTarget.style.left = `${left}px`;
  }

  checkDropdownSelected() {
    const more = this.moreTarget;
    const moreTextContainer = this.moreTextContainerTarget;
    const moreTextValue = this.moreTextValue;
    const allItemsTextValue = this.allItemsTextValue;
    const dropdown = this.dropdownTarget;
    const activeFilter = dropdown.querySelector(".active");

    if (activeFilter) {
      more.classList.add("selected");
      moreTextContainer.innerHTML = activeFilter.innerHTML;
    } else {
      more.classList.remove("selected");
      if (moreTextValue) {
        moreTextContainer.innerText = moreTextValue;
      } else {
        if (this.allItemsIconPathValue) {
          const iconElement = `<img src="${this.allItemsIconPathValue}" alt="All items" />`;
          moreTextContainer.innerHTML = iconElement + allItemsTextValue;
        } else {
          moreTextContainer.innerText = allItemsTextValue;
        }
      }
    }
  }

  handleClickOutside(event) {
    if (!this.dropdownTarget.contains(event.target) && !this.moreTarget.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.dropdownTarget.classList.add("hol-hidden"); // Hide the popup
    document.removeEventListener("click", this.handleClickOutside); // Remove the event listener
  }
}
