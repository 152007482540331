export const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

export const flatpickrDefaultOptions = isPatient => ({
  minDate: !isPatient ? new Date() : getTomorrow(),
  inline: true,
  time_24hr: true,
});
