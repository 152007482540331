import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['allSessionsModal'];

  static values = {
    hiddenClass: { type: String, default: 'hol-hidden' },
  };

  connect() {
    console.log('all appointments controller connected');
  }

  toggleAllSessions() {
    console.log('toggle all sessions');
    this.allSessionsModalTarget.classList.toggle(this.hiddenClassValue);
  }

  closeAllSessionsModal() {
    console.log('close all sessions');
    this.allSessionsModalTarget.classList.add(this.hiddenClassValue);
  }

  stopPropagation(e) {
    e.stopPropagation();
  }
}
