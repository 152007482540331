import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    url: { type: String, default: '/' },
    delay: { type: Number, default: 12 } // seconds
  }

  connect() {
    setTimeout(() => {
      Turbo.visit(this.urlValue);
    }, this.delayValue * 1000); // 10000 milliseconds = 10 seconds
  }
}
